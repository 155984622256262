<template>
  <div class="home">
    <Header />
    <RoomList title="Featured Rooms" />
    <!-- <AccomList /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import RoomList from '@/components/RoomList'

export default {
  name: 'home',
  components: {
    Header,
    RoomList
  }
}
</script>
