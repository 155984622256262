import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faTimes, faMapMarkedAlt, faPhone} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLine } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBars, faTimes, faFacebook, faInstagram, faLine, faMapMarkedAlt, faPhone)

Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {id: 'G-EHDDBR8QLL'}
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
