<template>
    <div class="nav">
        <router-link to="/" class="nav-logo">
            <img id="logo" src="@/assets/logo-small.png" alt="Logo">
        </router-link>
        <div class="nav-links">
            <router-link v-for="(l,i) in links" :key="i" :to="l.to">
                {{l.name}}
            </router-link>
            <a href="https://facebook.com/43homep" target="_blank"><fa-icon :icon="['fab', 'facebook']" /></a>
            <a href="https://line.me/ti/p/CD2YU_6Ge5" target="_blank"><fa-icon :icon="['fab', 'line']" /></a>
        </div>

        <div id="open-close">
            <a @click="toggleMenu">
                <fa-icon v-if="!menu" icon="bars" />
                <fa-icon v-if="menu" icon="times" />
            </a>
        </div>
        <div class="nav-mobile" v-if="menu">
            <router-link v-for="(l,i) in links" :key="i" :to="l.to">
                {{l.name}}
            </router-link>
            <a href="https://facebook.com/43homep" target="_blank"><fa-icon :icon="['fab', 'facebook']" /></a>
            <a href="https://line.me/ti/p/CD2YU_6Ge5" target="_blank"><fa-icon :icon="['fab', 'line']" /></a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menu: false,
            links: [
            {
                name: 'Home',
                to: '/'
            },
            {
                name: 'Rooms',
                to: '/rooms'
            },
            {
                name: 'Accomodations',
                to: '/accom'
            },
            {
                name: 'Contact Us',
                to: '/about'
            }
            ]
        }
    },
    methods: {
        toggleMenu() {
            this.menu = !this.menu
        }
    },
    watch: {
    $route: function() {
        this.menu = false
    }
  }
}
</script>

<style scoped>
    * {
        outline: 0;
    }

    .nav {
        display: flex;
        height: fit-content;
        background: #ccc;
        line-height: 70px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 20px;
    }

    /* .nav {
        height: 70px;
        line-height: 70px;
        background-color: #ccc;
        text-align: right;
    } */

    #logo {
        height: 70px;
    }

    .nav-logo {
        text-align: left;
        height: 70px;
    }

    .nav-links{
        color: #fff;
    }

    .nav-links a {
        display: inline-block;
        padding: 0 10px;
        color: #333;
        text-decoration: none;
        transition: all 0.4s;
    }

    .nav-links a::after {
        content: '';
        display: block;
        margin-top: -10px;
        width: 0;
        height: 2px;
        background: #333;
        transition: width .3s;
    }

    .nav-links a:hover::after {
        width: 100%;
    }

    .nav-links a:hover {
        color: #666;
    }

    .nav-links a.router-link-exact-active::after {
        width: 100%;
    }

    .nav-links a svg {
        transform: scale(1.3);
    }

    /* nav-mobile sould be hidden in desktopp anyways */
    .nav-mobile {
        display: none;
    }
    #open-close {
        display: none;
    }

    @media only screen and (max-width: 600px) {
        .nav {
            display: flex;
            height: fit-content;
            line-height: 70px;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0;
        }

        #logo {
            margin-left: 20px;
        }

        #open-close {
            display: block;
        }

        #open-close a svg {
            transform: scale(1.5);
        }

        #open-close a{
            padding: 20px;
            margin-right: 20px;
            transition: all 0.2s;
        }

        #open-close a:hover{
            color: #fff;
            background: #333;
        }

        .nav-links {
            display: none;
        }

        .nav-mobile {
            width: 100%;
            display: block;
            color: #fff;
            margin: 0 auto;
        }

        .nav-mobile a {
            display: block;
            text-align: center;
            color: #333;
            text-decoration: none;
            transition: all 0.2s;
        }

        .nav-mobile a:hover {
            display: block;
            text-align: center;
            background: #333;
            color: #fff;
            text-decoration: none;
        }

        .nav-mobile a svg {
            transform: scale(1.5);
        }
    }
</style>
