<template>
  <div id="about">
    <div class="title-container">
      Contact Us
    </div>
    <div class="container">
      <h1>Contact Info</h1>
      <ul>
        <li v-for="c in contacts" :key="c.text">
          <fa-icon :icon="c.icon" />
          <a :href="c.to" target="_blank">{{c.text}}</a>
        </li>
      </ul>
      <h1>Map</h1>
      <div id="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15501.671423346876!2d100.4774105!3d13.7536566!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x906bf2541f75d1d6!2s43+Hotel+Phrannok!5e0!3m2!1sen!2sth!4v1545909895859"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contacts: [
        {
          text: 'Facebook: 43 Hotel Phrannok',
          icon: ['fab', 'facebook'],
          to: 'https://facebook.com/43homep'
        },
        {
          text: 'LINE: 0838382640',
          icon: ['fab','line'],
          to: 'https://line.me/ti/p/CD2YU_6Ge5'
        },
        {
          text: 'Phone: 083-838-2640',
          icon: 'phone',
          to: 'tel:083-838-2640'
        },
        {
          text: 'Address: 181/299 Soi Itsaraphap 43, Itsaraphap Rd., Banchanglo, Bangkoknoi, Bangkok 10700',
          icon: 'map-marked-alt',
          to: 'https://goo.gl/maps/FLJ8XYD8aNv'
        }
      ]
    }
  }
}
</script>

<style scoped>

  .container {
    text-align: center;
  }

  .container ul {
    text-align: left;
    list-style-type: none;
    width: 80vw;
    margin: 0 auto;
    font-size: 15pt;
    line-height: 40px;
  }

  .container ul li {
    padding: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  .container ul li a {
    text-decoration: none;
    color: #333;
  }

  .container ul li svg {
    transform: scale(1.2);
    margin-right: 15px;
  }

  /* #map {
    margin: 0 auto;
  } */

  table {
    width: 75vw;
    margin: 0 auto;
  }

  table tr {
    height: 10vh;
  }

  iframe {
   width: 100%;
   height: 60vh;
  }

  @media only screen and (max-width: 736px) {
    iframe {
      width: 90vw;
    }

    .container ul {
      text-align: left;
      list-style-type: none;
      width: 80vw;
      margin: 0 auto;
      font-size: 15pt;
      line-height: 40px;
    }

    .container ul li {
      padding: 10px 0;
      cursor: pointer;
      transition: all .2s ease-in-out;
    }
  }
</style>
