<template>
    <div>
        <!-- Add 'Full to the Attrib for more than 3 in list' -->
        <RoomList title="All Rooms" />
    </div>
</template>

<script>
import RoomList from '@/components/RoomList.vue'

export default {
    components: {
        RoomList
    }
}
</script>
