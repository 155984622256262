<template>
    <div id="accom">
        <div class="title-container">
            Accomodations
        </div>
        <div v-for="(a, i) in accoms" :key="i">
            <div :class="(i%2 == 0) ? 'row' : 'row reverse' ">
                <div class="col gray">
                    <div class="content">
                        <div>
                          <span id="title">
                              {{a.name}}
                          </span>
                          <br>
                          <span id="subtitle">
                            {{a.desc}}
                          </span>
                        </div>
                    </div>
                </div>
                <div class="col" style="background: #000">
                    <img id="room-preview" :src="a.img" alt="Bed" srcset="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
      accoms: [
        {
          name: 'Elevator',
          img: require('@/assets/ele-closed.jpeg'),
          desc: 'Go from 1st to 5th floor in no time with our elevator!'
        },
        {
          name: 'Free WiFi',
          img: require('@/assets/front.jpeg'),
          desc: 'Enjoy surfing the Internet to you heart\'s content with our free high-speed WiFi!'
        },
        {
          name: 'Morning Coffee',
          img: require('@/assets/bar.jpeg'),
          desc: 'Feel free to have some coffee and Ovaltine in the morning'
        },
        {
          name: 'Smart TV in room',
          img: require('@/assets/tv-room.jpeg'),
          desc: 'Android-powered smart TV available in every room'
        }
      ]
    }
  },
  components: {}
}
</script>

<style src="./flex-list.css"></style>
