<template>
    <div id="room-list">
        <div class="title-container">
            {{title}}
        </div>
        <div v-for="(r, i) in (full) ? rooms : previewRooms" :key="i">
            <div :class="(i%2 == 1) ? 'row' : 'row reverse' ">
                <div class="col gray">
                    <div class="content">
                        <div>
                          <span id="title">
                              {{r.title.toUpperCase()}}
                          </span>
                          <br>
                          <span id="subtitle">
                              {{'room'.toUpperCase()}}
                          </span>
                        </div>
                        <!-- <router-link to="/" id="btn-details">
                            Details
                        </router-link> -->
                    </div>
                </div>
                <div class="col" style="background: #000">
                    <img id="room-preview" :src="r.img" alt="Bed" srcset="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rooms: [
                {
                    title: 'Single Bed',
                    img: require('@/assets/403-bed.jpeg'),
                    to: '/rooms/single'
                },
                {
                    title: 'Double Bed',
                    img: require('@/assets/bed-double.jpeg'),
                    to: '/rooms/double'
                },
                {
                    title: 'Triple Bed',
                    img: require('@/assets/bed-triple.jpeg'),
                    to: '/rooms/triple'
                },
                {
                    title: 'Single - 6ft. Bed',
                    to: 'rooms/6ft'
                }
            ]
        }
    },
    computed: {
        previewRooms: function() {
            return this.rooms.filter(function(v,i){return (i < 3) ? v : null })
        }
    },
    props: {
        title: String,
        full: Boolean
    }
}
</script>

<style src="./flex-list.css"></style>
