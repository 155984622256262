<template>
  <accom-list />
</template>
<script>
import AccomList from '@/components/AccomList'

export default {
  components: {
    AccomList
  }
}
</script>
<style>
</style>
