<template>
  <div id="app">
    <!-- Link fontAwesome -->
    <navbar />
    <transition name="fade">
      <router-view/>
    </transition>
    <Footer />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  components: {Navbar, Footer}
}
</script>

<style src="@/main.css"></style>

